import EventCard from "./EventCard";

const AllEventList = ({ events }) => {
  return (
    <div className="w-full">
      <h2 className="text-3xl font-medium">Upcoming Events</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-8 gap-4 pb-16">
        {events.map((obj, i) => (
          <EventCard key={obj?._id} eventData={obj} i={i} />
        ))}
      </div>
    </div>
  );
};

export default AllEventList;
