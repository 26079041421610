import Link from "next/link";
import PropTypes from "prop-types";
import DOMPurify from "isomorphic-dompurify";
import AccessibleImage from "./AccessibleImage";

const ProgrammeCard = ({ programmeData }) => (
  <Link href={`/calendar/${programmeData._id}/cover`}>
    <div className="overflow-hidden hover:shadow-lg hover:border-gray-300 flex text-left flex-col justify-start bg-white rounded-lg border border-greyClear h-full">
      <AccessibleImage
        className="w-full h-36 object-cover"
        img={programmeData.coverImage}
      />
      <div className="p-4">
        <h3 className="text-xl font-medium">{programmeData.name}</h3>
        <p className="text-gray-700 mt-2 text-sm line-clamp-5 whitespace-pre-wrap">
          {DOMPurify.sanitize(programmeData.description, "text/html")
            .match(/(?:^|>)[^><]+?(?=<|$)/g)?.[0]
            ?.slice(1)}
        </p>
      </div>
    </div>
  </Link>
);

ProgrammeCard.propTypes = {
  programmeData: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    coverImage: PropTypes.object.isRequired,
  }).isRequired,
};

const ProgrammesList = ({ programmes }) => {
  return (
    <div className="w-full">
      <h2 className="text-3xl font-medium">Community Programmes</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-8 gap-4 pb-4">
        {programmes.map((obj, i) => (
          <ProgrammeCard key={obj._id} programmeData={obj} i={i} />
        ))}
      </div>
    </div>
  );
};

export default ProgrammesList;
