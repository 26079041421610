import Head from "next/head";
import React from "react";
import AllEventList from "../components/AllEventList";
import ProgrammesList from "../components/ProgrammesList";
import { SITE_NAME } from "../config";
import {
  fetchHomePageProgrammes,
  fetchHomePageEvents,
} from "../methods/serverDataFunctions";

// Ignore demo org events
const demoOrgIds = [
  "afzODlsUnwNFw3YX5qT9",
  "w6XA0KqnuPVGeBwOF3JW",
  "A6pVxFJ9hMVeBwVxCI8H",
  "f4jf2FbLXwlBSwBOg1Ly",
  "Mq4GUMEpsNyhi4twrWMA",
  "U2HWefjL2o6xDZRmPuGY",
  "6LfwYzAiGlmfaQoWmjwk",
  "H4m9mIKUX8EMHtSo6IPz",
];

function Home({ programmes, events }) {
  return (
    <div>
      <div className="relative">
        <Head>
          <title>{SITE_NAME}</title>
          <link rel="icon" href="/favicon.png" />
        </Head>
        <img
          src="/green-smudge-right.svg"
          alt=""
          className="fixed right-0 top-0 h-full w-auto"
        />
        <img
          src="/purple-smudge-left.svg"
          alt=""
          className="absolute left-0 top-0 z-0"
        />
        <div
          style={{
            height:
              typeof window !== "undefined" && window?.innerWidth < 737
                ? "auto"
                : "calc(100vh - 64px)",
          }}
          className="w-full max-w-2xl mx-auto relative flex overflow-hidden z-10"
        >
          <div className="max-w-2xl z-10 relative mx-auto px-4 w-full mt-6 md:mt-12">
            <h1
              style={{ width: "fit-content" }}
              className="flex text-6xl font-lora text-gray-800  rounded-lg px-2 py-4 z-50 relative"
            >
              Activities in your <br />
              community
            </h1>
            <p className="max-w-2xl mt-2 text-lg">
              Find out what&apos;s happening in your community. From events to
              programmes, there&apos;s something for everyone.
            </p>
            <div className="rounded-md mt-16 flex items-start">
              <a
                href="#events"
                className="z-50 w-60 h-12 px-8 py-2 
                btn-primary md:py-4 md:text-lg md:px-10"
              >
                Find Something
              </a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-64 ml-12 z-50 -mt-8 md:mt-0"
                fill="none"
                viewBox="0 0 477 699"
              >
                <path
                  fill="#000"
                  d="M120.366 687.343a3 3 0 002.916 3.081l26.99.738a2.998 2.998 0 003.081-2.916 2.999 2.999 0 00-2.917-3.081l-23.991-.657.657-23.991a3 3 0 00-5.998-.164l-.738 26.99zM8.468 98.704c94.51-51.55 178.234-68.313 243.186-57.861 64.816 10.43 111.083 47.954 131.212 105.574 20.184 57.778 14.237 136.223-26.594 228.503-40.814 92.243-116.384 198.057-234.97 310.326l4.125 4.357c118.997-112.657 195.115-219.101 236.332-312.255 41.201-93.116 47.628-173.207 26.771-232.91-20.912-59.861-69.104-98.766-135.923-109.519-66.684-10.73-151.772 6.569-247.012 58.518l2.873 5.267z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        id="programmes"
        className="max-w-7xl pt-8 mx-auto sm:px-6 w-full lg:px-8 px-4 border-t border-gray-200 relative z-10"
      >
        <ProgrammesList programmes={programmes} />
      </div>
      <div
        id="events"
        className="max-w-2xl mt-8 px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 w-full relative z-10"
      >
        <AllEventList events={events} />
      </div>
    </div>
  );
}

export async function getStaticProps() {
  try {
    const programmes = await fetchHomePageProgrammes();
    const events = await fetchHomePageEvents(demoOrgIds);

    return {
      props: {
        // Load a list of cut-down programmes objects
        programmes,
        events,
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every second
      revalidate: 600, // In seconds
    };
  } catch (err) {
    console.log(err);
    return { notFound: true };
  }
}

export default Home;
